@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@300;400;500;600;700;800;900&family=Manrope:wght@400;500;600;700;800&display=swap');



:root
{
  --marginTop: 16px;
  --marginBottom: 16px;
  --marginLeft: 16px;
  --marginRight: 16px;
  --themeColor: #7e7677;
  --logoHeight: 40px;
  --fontColor: #ffffff;
  --bodyBackground: #1f2022;
  
}

[data-theme="dark"] {
  --bodyBackground: #1f2022;
}

[data-theme="light"] {
  --bodyBackground: rgb(101, 21, 21);  
  }


*
{
  transition: all 0.3s ease-in-out ;
}

body {

font-family: 'Manrope', sans-serif;

}



.App {
  text-align: center;

}

.App-logo {

height: var(--logoHeight);
  pointer-events: none;
  margin-bottom: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {

    height: 52px;
   
  }
}

.App-header {
  background-color: var(--bodyBackground);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.dark_mode {
  position: absolute;
  margin-top: -400px;
  margin-left: 10px;
}

.dark_mode_label {
  width: 65px;
  height: 30px;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
      inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.3s;
}
.dark_mode_label:after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.dark_mode_input {
  width: 0;
  height: 0;
  visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
  background: #242424;
}
.dark_mode_input:checked + .dark_mode_label:after {
  left: 62px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}
.dark_mode_label:active:after {
  width: 30px;
}

.dark_mode_label svg {
  position: absolute;
  width: 20px;
  top: 5px;
  z-index: 100;
}
.dark_mode_label svg.sun {
  left: 5px;
  fill: #fff;
  transition: 0.3s;
}
.dark_mode_label svg.moon {
  left: 40px;
  fill: #7e7e7e;
  transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
  fill: #7e7e7e;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
  fill: #fff;
}

